<script>
import { Bar, mixins } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const { reactiveData } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveData],
  props: ['data', 'chartType'],
  data() {
    return {
      chartData: {
        labels: [],
        datasets: []
      },
      options: {
        plugins: {
          datalabels: {
            color: '#ffffff',
            font: {
              weight: 'bold',
              size: 12
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scaleShowValues: true,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              stacked: true,
              autoSkip: false,
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    };
  },

  mounted() {
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    data(value) {
      this.chartData = {
        ...this.chartData,
        labels: value.map(i => i[this.chartType], 'Total'),

        datasets: [
          {
            label: `Single Order: ${value.reduce((i, j) => i + j.singleOrderCount, 0)}`,
            backgroundColor: 'Black',
            hoverBackgroundColor: 'Black',
            data: value.map(i => i.singleOrderCount)
          },
          {
            label: `Bulk Order: ${value.reduce((i, j) => i + j.bulkOrderCount, 0)}`,
            backgroundColor: 'Orange',
            hoverBackgroundColor: 'Orange',
            data: value.map(i => i.bulkOrderCount)
          },
          {
            label: `Acknowledge Count: ${value.reduce((i, j) => i + j.acknowledgeCount, 0)}`,
            backgroundColor: 'Green',
            hoverBackgroundColor: 'green',
            data: value.map(i => i.acknowledgeCount)
          },
          {
            label: `IVR Order Count: ${value.reduce((i, j) => i + j.ivrOrderCount, 0)}`,
            backgroundColor: 'fuchsia',
            hoverBackgroundColor: 'fuchsia',
            data: value.map(i => i.ivrOrderCount)
          }
        ]
      };
    }
  }
};
</script>

