<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ titleCurrent }}</h1>
    <table-box
      :columns="columnsCurrent"
      :rows="currentCallsList"
      :baseUrl="baseUrl"
      :loading="loading"
      emptyText="No logs found"
      :showAdd="false"
      :showPagination="false"
      @add-order="onAddOrder"
    >
    </table-box>
    <h1 class="page-title">{{ titleHistory }}</h1>
    <table-box-analytics
      :columns="columnsHistory"
      :rows="callsList"
      :pagination="pagination"
      :baseUrl="baseUrl"
      :loading="loading"
      :filterOptions="filterOptions"
      :callStatusOptions="callStatusOptions"
      emptyText="No logs found"
      downloadText="Download Call Logs"
      :showAdd="true"
      :showDownload="true"
      :showFilter="true"
      :showPagination="true"
      :searchItems="searchItems"
      :userRole="user.role"
      addText="Download IVR Report"
      @add-order="onAddOrder"
      @download="onExportCSV"
      @change-status="onChangeStatus"
      @add="onDownloadReport"
    >
    </table-box-analytics>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TableBox from '@/components/TableBox.vue';
import TableBoxAnalytics from '@/components/TableBoxAnalytics.vue';
import router from '@/router';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'CallsList',
  components: {
    TableBox,
    TableBoxAnalytics
  },
  metaInfo() {
    return {
      title: 'Calls',
      meta: [
        {
          name: 'description',
          content: `See Calls List`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.list({ query: this.$route.query });
    }
    this.startUpdateCurrentCallList();
  },
  beforeUnmount() {
    if (this.polling) {
      clearInterval(this.polling);
    }
  },
  created() {
    if (!this.$vueSocketIo.emitter.listeners.get(this.user.storeids[0])) {
      this.sockets.subscribe(this.user.storeids[0], data => {
        this.updateCurrentCallList({ data, storeid: this.user.storeids[0] });
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.list({ query: to.query });
    next();
  },
  data() {
    return {
      titleCurrent: 'Current Calls',
      titleHistory: 'Call History',
      columnsCurrent: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'id',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Caller Id',
          class: { 'text-center': true },
          textKey: 'callerId',
          width: '25%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '25%'
        },
        {
          type: 'string',
          headerText: 'Date',
          class: { 'text-center': true },
          textKey: 'datetime',
          width: '25%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '15%',
          functions: {
            addOrder: true,
            edit: false,
            delete: false
          }
        }
      ],
      columnsHistory: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'id',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Caller Id',
          class: { 'text-center': true },
          textKey: 'callerId',
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Call Time (In Seconds)',
          class: { 'text-center': true },
          textKey: 'duration',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Status',
          class: { 'text-center': true },
          textKey: 'disposition',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'datetime',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Updated At',
          class: { 'text-center': true },
          textKey: 'updatedAt',
          width: '15%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '20%',
          functions: {
            addOrder: true,
            edit: false,
            delete: false,
            callStatus: true
          }
        }
      ],
      polling: null,
      searchItems: ['Customer Mobile', 'Store Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'CALL-ANSWERED', text: 'Call - ANSWERED' },
        { value: 'CALL-NO_ANSWER', text: 'Call - NO ANSWER' },
        { value: 'CALL-BUSY', text: 'Call - BUSY' },
        { value: 'FEEDBACK-NOT_SELECTED', text: 'Order - Not Selected' },
        { value: 'FEEDBACK-SERVICED', text: 'Order - Order Serviced' },
        { value: 'FEEDBACK-NOT_AVAILABLE', text: 'Order - Product Not Available' },
        { value: 'FEEDBACK-ENQUIRY_CALL', text: 'Order - Enquiry Call' },
        { value: 'FEEDBACK-OUT_OF_AREA', text: 'Order - Out of Serviceable Area' },
        { value: 'FEEDBACK-NOT_REACHABLE', text: 'Order - Customer Not Reachable' }
      ],
      callStatusOptions: [
        { value: 0, text: 'Select Status' },
        { value: 1, text: 'Order Serviced' },
        { value: 2, text: 'Product Not Available' },
        { value: 3, text: 'Enquiry Call' },
        { value: 4, text: 'Out of Serviceable Area' },
        { value: 5, text: 'Customer Not Reachable' }
      ]
    };
  },
  computed: {
    ...mapState('calls', [
      'loading',
      'baseUrl',
      'callsList',
      'currentCallsList',
      'pagination',
      'downloadCallsList',
      'downloadReport'
    ]),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('calls', [
      'list',
      'download',
      'updateCurrentCallList',
      'scheduleUpdateCurrentCallsList',
      'setDownloadListToEmpty',
      'updateCallStatusFeedback',
      'report'
    ]),
    onAddOrder({ row }) {
      if (row.callerId && (row.callerId.length === 10 || row.callerId.length === 12)) {
        router.push(`/order/new/${row.callerId}`);
      } else {
        router.push('/order/new');
      }
    },
    onDownloadReport() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.report({ query: { ...this.$route.query } });
      } else {
        this.report({
          query: {
            ...this.$route.query,
            startdate: moment().format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },
    startUpdateCurrentCallList() {
      this.polling = setInterval(() => {
        this.scheduleUpdateCurrentCallsList();
      }, 10000);
    },
    onExportCSV() {
      this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
    },
    onChangeStatus({ row, event }) {
      this.updateCallStatusFeedback({
        query: {
          id: row.id,
          status: event
        }
      });
    }
  },
  watch: {
    downloadReport(newValue) {
      if (newValue.length) {
        let csv =
          'Row Num,Store Id,Store Name,Region,Cluster,Format,ASM Name,Total Call Recived,Total Order Converted,Total Sales\n';
        newValue.forEach((item, index) => {
          csv += `${index + 1},${item.storeId},${item.storeName},${item.storeRegion},${item.storeCluster},${
            item.storeFormat
          },${item.ASMName},${item.inboundCallRecived},${item.orderDataLength},${item.posValue}\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'IVR Report.csv';
        anchor.click();
      }
    },
    downloadCallsList(newValue) {
      if (newValue.length) {
        let csv = 'Id,Caller Id,Call Status,Store Id,Date,Call Time,Feedback\n';
        newValue.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Call Logs.csv';
        anchor.click();
        this.setDownloadListToEmpty();
      }
    }
  }
};
</script>
