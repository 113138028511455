<template>
  <div>
    <div v-if="viewsSelected === 'admin'">
      <b-table striped responsive bordered hover head-variant="" :fields="fields1" :items="items1">
        <template #thead-top>
          <template>
            <!-- <b-tr>
            <b-th colspan="18">Store Name - {{ title }} </b-th>
          </b-tr> -->
          </template>
          <b-tr>
            <b-th colspan="13">Store Level</b-th>
            <b-th colspan="5">Feedback</b-th>
          </b-tr>
        </template>
      </b-table>
    </div>

    <div v-else-if="viewsSelected === 'store'">
      <b-table striped responsive bordered hover head-variant="" :fields="fields2" :items="items2">
        <template #thead-top>
          <b-tr>
            <b-th colspan="1">Store Level</b-th>
            <b-th colspan="1">Store No</b-th>
            <b-th colspan="1">Store Name</b-th>
            <b-th colspan="1">Zone</b-th>
            <b-th colspan="5">Feedback</b-th>
          </b-tr>
        </template>
      </b-table>
    </div>

    <div v-else-if="viewsSelected === 'am'">
      <b-table striped responsive bordered hover head-variant="" :fields="fields3" :items="items3">
        <template #thead-top>
          <b-tr>
            <b-th colspan="1">Store Level</b-th>
            <b-th colspan="1">Store No</b-th>
            <b-th colspan="1">Store Name</b-th>
            <b-th colspan="5">Feedback</b-th>
          </b-tr>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['misTable', 'viewsSelected'],
  data() {
    return {
      title: 'Store Name',
      fields1: [
        { key: 'storeid', label: 'Store' },
        { key: 'region', label: 'Region' },
        { key: 'cluster', label: 'Cluster' },
        { key: '', label: 'ASM' },
        { key: '', label: 'Total Assigned Caller' },
        { key: '', label: 'Total Active Caller' },
        { key: '', label: 'Total Active Time' },
        { key: '', label: 'Active Time Per Caller' },
        { key: '', label: 'Total Calling Time(Total Talk Time During Day' },
        { key: '', label: 'Calling Time Per Caller' },
        { key: '', label: 'Total Unique Call Target' },
        { key: 'callLandedTotalCount', label: 'Total Call Made' },
        { key: '', label: 'Total Calls Made on Unique Number' },
        { key: '', label: 'Efficiency%' },
        { key: 'callsLandedNotAnswered', label: 'Busy/Not Picked/Disconnected' },
        { key: 'singleOrderCount', label: 'Order Taken' },
        { key: '', label: 'Will Decide And Call Back Later' },
        { key: '', label: 'Call Back Later' },
        { key: '', label: "Not Interested,Don't Call Again" }
      ],
      fields2: [
        { key: 'storeid', label: 'Outbound Caller' },
        { key: 'bulkOrderCount', label: 'Date(DD/MM/YYYY)' },
        { key: '', label: 'Name Of Caller' },
        { key: '', label: 'First Login Time(HH:MM:SS)' },
        { key: '', label: 'Last Logout Time' },
        { key: '', label: 'Total Active Time' },
        { key: '', label: 'Total Calling Time' },
        { key: '', label: 'Total Unique Call Target' },
        { key: 'callLandedTotalCount', label: 'Total Call Made' },
        { key: '', label: 'Total Calls Made on Unique Number' },
        { key: '', label: 'Efficiency%' },
        { key: 'callsLandedNotAnswered', label: 'Busy/Not Picked/Disconnected' },
        { key: 'singleOrderCount', label: 'Order Taken' },
        { key: '', label: 'Will Decide And Call Back Later' },
        { key: '', label: 'Call Back Later' },
        { key: '', label: "Not Interested,Don't Call Again" }
      ],
      fields3: [
        { key: 'storeid', label: 'Site Code' },
        { key: 'bulkOrderCount', label: 'Store Name' },
        { key: '', label: 'Date' },
        { key: '', label: 'SM Name' },
        { key: '', label: 'Total Assigned Caller' },
        { key: '', label: 'Total Active Caller' },
        { key: '', label: 'Total Active Time' },
        { key: '', label: 'Active Time Per Caller' },
        { key: '', label: 'Total Calling Time(Total Talk Time During Day' },
        { key: '', label: 'Calling Time Per Caller' },
        { key: '', label: 'Total Unique Call Target' },
        { key: 'callLandedTotalCount', label: 'Total Call Made' },
        { key: '', label: 'Total Calls Made on Unique Number' },
        { key: '', label: 'Efficiency%' },
        { key: 'callsLandedNotAnswered', label: 'Busy/Not Picked/Disconnected' },
        { key: 'singleOrderCount', label: 'Order Taken' },
        { key: '', label: 'Will Decide And Call Back Later' },
        { key: '', label: 'Call Back Later' },
        { key: '', label: "Not Interested,Don't Call Again" }
      ],
      items1: [],
      items2: [],
      items3: []
    };
  },
  watch: {
    misTable(value) {
      this.items1 = JSON.parse(JSON.stringify(value));
      this.items2 = JSON.parse(JSON.stringify(value));
      this.items3 = JSON.parse(JSON.stringify(value));

      // this.items = this.misTable.map(misItem => {
      //   return this.fields.map(item => {
      //     return misItem[item.id] || '';
      //   });
      // });
    }
  }
};
</script>
