<template>
  <div>
    <h1 class="page-title">{{ title }}</h1>
    <b-row class="table-top-wrapper mb-2 mx-0">
      <b-col sm class="px-0">
        <b-form inline>
          <b-form-select
            v-model="regionSelected"
            v-if="showNavigation()"
            :options="regions"
            class="ml-2 w-35 mb-2"
          ></b-form-select>
          <b-form-select
            v-model="clusterSelected"
            v-if="showNavigation()"
            :options="clusters"
            class="ml-2 w-35 mb-2"
          ></b-form-select>
          <b-form-select v-model="storeSelected" :options="stores" class="ml-2 w-35 mb-2"></b-form-select>
          <b-form-select
            v-model="viewsSelected"
            v-if="showNavigation()"
            :options="views"
            class="ml-2 w-35 mb-2"
          ></b-form-select>
          <b-form-datepicker
            id="start-datepicker"
            placeholder="Start Date"
            :min="minDate"
            :max="maxDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            v-model="startDateValue"
            class="ml-2 mb-2"
          ></b-form-datepicker>
          <b-form-datepicker
            id="end-datepicker"
            placeholder="End Date"
            :min="minDate"
            :max="maxDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            v-model="endDateValue"
            class="ml-2 mb-2"
          ></b-form-datepicker>
          <b-button variant="success" class="ml-2 mb-2" @click="showTable">Show Table</b-button>
        </b-form>
      </b-col>
    </b-row>
    <!-- {{ Object.keys(misTable) }} -->
    <mis-table-box
      v-if="isTableVisible"
      :misTable="misTable"
      :regionList="regionList"
      :clusterList="clusterList"
      :storeList="storeList"
      :viewsSelected="viewsSelected"
    >
    </mis-table-box>
  </div>
</template>

<script>
import moment from 'moment';
import router from '@/router';
import { mapState, mapActions } from 'vuex';
import MisTableBox from '../../../components/MisTableBox.vue';
import User from '../../../model/user';

export default {
  name: 'MisDataTable',
  metaInfo() {
    return {
      title: 'MisDataTable',
      meta: [
        {
          name: 'mis-table',
          content: `tables`
        }
      ]
    };
  },
  components: { MisTableBox },
  data() {
    return {
      title: 'Mis Table',
      isTableVisible: false,
      regions: [{ text: 'All Regions', value: 'all' }],
      clusters: [{ text: 'All Clusters', value: 'all' }],
      stores: [{ text: 'All Stores', value: 'all' }],
      views: [
        { text: 'Admin View', value: 'admin' },
        { text: 'Store View', value: 'store' },
        { text: 'By Store By AM', value: 'am' }
      ],
      regionSelected: 'all',
      clusterSelected: 'all',
      storeSelected: 'all',
      viewsSelected: 'admin',
      startDateValue: moment().format('YYYY-MM-DD'),
      endDateValue: moment().format('YYYY-MM-DD'),
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD')
    };
  },
  mounted() {
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
  },
  methods: {
    ...mapActions('mis', ['download']),
    ...mapActions('store', ['listStore', 'listCluster', 'listRegion']),
    downloadMIS({ download = true }) {
      let storesList = [];
      if (this.storeSelected === 'all') {
        storesList = this.stores.map(s => s.value);
        storesList.shift();
      } else {
        storesList = [this.storeSelected];
      }
      this.download({
        query: {
          startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
          enddate: moment(this.endDateValue).format('YYYY-MM-DD'),
          stores: storesList
        },
        download
      });
    },
    showTable() {
      this.downloadMIS({ download: false });
      this.isTableVisible = true;
    },
    showNavigation() {
      if (this.user.role === User.userRole.administrator) {
        return true;
      }
      return false;
    }
  },

  computed: {
    ...mapState('mis', ['misTable']),
    ...mapState('store', ['storeList', 'clusterList', 'regionList']),
    ...mapState('auth', ['user'])
  },

  watch: {
    regionSelected(value) {
      if (value === 'all') {
        this.listStore({ router });
        this.listCluster({ router, query: { type: 'cluster' } });
      } else {
        this.listStore({ router, query: { by: 'region', value } });
        this.listCluster({ router, query: { type: 'cluster', by: 'region', value } });
      }
    },
    clusterSelected(value) {
      if (value === 'all') {
        this.listStore({ router });
      } else {
        this.listStore({ router, query: { by: 'cluster', value } });
      }
    },
    clusterList(newValue) {
      this.clusters = [{ text: 'All Clusters', value: 'all' }];
      this.clusters = [...this.clusters, ...newValue];
    },
    regionList(newValue) {
      this.regions = [{ text: 'All Regions', value: 'all' }];
      this.regions = [...this.regions, ...newValue];
    },
    storeList(newValue) {
      if (this.user.role === User.userRole.administrator) {
        this.stores = [{ text: 'All Stores', value: 'all' }];
        this.stores = [...this.stores, ...newValue];
      } else {
        this.stores = [{ text: 'All Stores', value: 'all' }];
        this.stores = [...this.stores, ...this.user.storeids.map(store => ({ text: store, value: store }))];
      }
    }
  }
};
</script>

<style>
</style>
