<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <table-box-analytics :columns="columns" :rows="analyticsList" :pagination="pagination" :baseUrl="baseUrl"
      :loading="loading" :filterOptions="filterOptions" :searchItems="searchItems" :showDownload="true" :showAdd="true"
      :showFilter="true" :showPagination="true" downloadText="Download Call Logs" emptyText="No logs found"
      addText="Download IVR Report" @download="onExportCSV" @add="onDownloadReport">
    </table-box-analytics>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TableBoxAnalytics from '@/components/TableBoxAnalytics.vue';
import router from '@/router';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'AnalyticsList',
  components: {
    TableBoxAnalytics
  },
  metaInfo() {
    return {
      title: 'Analytics',
      meta: [
        {
          name: 'description',
          content: `See Analytics`
        }
      ]
    };
  },
  beforeMount() {
    const { role } = this.user;
    const newUser = this.user.roles.map(role => role.name);
    const Creater = newUser.filter(role => role.includes('CREATE_CATALOGUE'));
    const Approver = newUser.filter(role => role.includes('APPROVE_CATALOGUE'));
    const CallCenter = newUser.filter(role => role.includes('CALL_CENTER_EXECUTIVE'));
    if (role && role !== 99) {
      router.push('/calls');
    }
    if (Creater.length > 0) {
      router.push('productsNew');
    }
    if (Approver.length > 0) {
      router.push('productsNew');
    }
    if (CallCenter.length > 0) {
      router.push('/order');
    }
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      let { query } = this.$route;
      if (!Object.prototype.hasOwnProperty.call(query, 'startdate')) {
        query = {
          ...query,
          startdate: moment().format('YYYY-MM-DD')
        };
      }
      if (!Object.prototype.hasOwnProperty.call(query, 'enddate')) {
        query = {
          ...query,
          enddate: moment().format('YYYY-MM-DD')
        };
      }
      this.list({ query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    let { query } = to;
    if (!Object.prototype.hasOwnProperty.call(query, 'startdate')) {
      query = {
        ...query,
        startdate: moment().format('YYYY-MM-DD')
      };
    }
    if (!Object.prototype.hasOwnProperty.call(query, 'enddate')) {
      query = {
        ...query,
        enddate: moment().format('YYYY-MM-DD')
      };
    }
    this.list({ query });
    next();
  },
  methods: {
    ...mapActions('analytics', ['list', 'download', 'setDownloadListToEmpty', 'report']),
    onExportCSV() {
      this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
    },
    onDownloadReport() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.report({ query: { ...this.$route.query } });
      } else {
        this.report({
          query: {
            ...this.$route.query,
            startdate: moment().format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    }
  },
  data() {
    return {
      title: 'Call Logs',
      columns: [
        {
          type: 'row_num',
          headerText: 'Id',
          class: { 'text-center': true },
          textKey: 'id',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Caller Id',
          class: { 'text-center': true },
          textKey: 'callerId',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Call Time',
          class: { 'text-center': true },
          textKey: 'time',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Pincode',
          class: { 'text-center': true },
          textKey: 'pincode',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Last Step',
          class: { 'text-center': true },
          textKey: 'step',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Manager Number',
          class: { 'text-center': true },
          textKey: 'managerNumber',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Duration in seconds',
          class: { 'text-center': true },
          textKey: 'duration',
          width: '10%'
        }
      ],
      searchItems: ['Caller Id', 'Pincode', 'Store Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Call Transfer', text: 'Call Transfer' },
        { value: 'Language selection', text: 'Language Selection' },
        { value: 'Preference Message', text: 'Preference Message' },
        { value: 'Night Mode check', text: 'Night Mode Check' },
        { value: 'Preference select 1', text: 'Preference Select 1' },
        { value: 'Preference select 2', text: 'Preference Select 2' },
        { value: 'Pincode input', text: 'Pincode Input' }
      ]
    };
  },
  computed: {
    ...mapState('analytics', [
      'loading',
      'baseUrl',
      'analyticsList',
      'pagination',
      'downloadAnalyticsList',
      'downloadReport'
    ]),
    ...mapState('auth', ['user'])
  },
  watch: {
    downloadReport(newValue) {
      if (newValue.length) {
        let csv =
          'Row Num,Store Id,Store Name,Region,Cluster,Format,ASM Name,Total Call Recived,Total Order Converted,Total Sales\n';
        newValue.forEach((item, index) => {
          csv += `${index + 1},${item.storeId},${item.storeName},${item.storeRegion},${item.storeCluster},${item.storeFormat
            },${item.ASMName},${item.inboundCallRecived},${item.orderDataLength},${item.posValue}\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'IVR Report.csv';
        anchor.click();
      }
    },
    downloadAnalyticsList(newValue) {
      if (newValue.length) {
        let csv = 'Id,Caller Id,Last Step,Pincode,Store Id,Duration (In Sec),Manager Number,Call Time\n';
        newValue.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Call Logs.csv';
        anchor.click();
        this.setDownloadListToEmpty();
      }
    }
  }
};
</script>
