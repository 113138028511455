<template>
  <div>
    <h1 class="page-title">{{ title }}</h1>
    <b-row class="table-top-wrapper mb-2 mx-0">
      <b-col sm class="px-0">
        <b-form inline>
          <b-form-select
            v-model="regionSelected"
            v-if="ifAdmin()"
            :options="regions"
            class="ml-2 w-35 mb-2"
          ></b-form-select>
          <b-form-select
            v-model="clusterSelected"
            v-if="ifAdmin()"
            :options="clusters"
            class="ml-2 w-35 mb-2"
          ></b-form-select>
          <b-form-select v-model="storeSelected" :options="stores" class="ml-2 w-35 mb-2"></b-form-select>

          <b-col sm class="px-0">
            <b-form inline class="float-right">
              <b-form-group id="group-name" label-for="input-name" class="ml-2 w-35 mb-2">
                <template v-slot:label> Start Date :- </template>
              </b-form-group>

              <b-form-datepicker
                id="start-datepicker"
                placeholder="Start Date"
                :min="minDate"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="startDateValue"
                class="ml-2 mb-2"
              ></b-form-datepicker>
              <b-form-group id="group-name" label-for="input-name" class="ml-2 w-35 mb-2">
                <template v-slot:label> End Date :- </template>
              </b-form-group>
              <b-form-datepicker
                id="end-datepicker"
                placeholder="End Date"
                :min="minDate"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="endDateValue"
                class="ml-2 mb-2"
              ></b-form-datepicker>
              <b-form-select
                v-model="chartSelected"
                v-if="ifAdmin()"
                :options="chartView"
                class="ml-2 w-35 mb-2"
              ></b-form-select>
            </b-form>
          </b-col>
        </b-form>
      </b-col>
      <b-button variant="success" class="ml-2 mb-2" @click="showChart">Show Chart</b-button>
    </b-row>
    <b-button variant="success" class="ml-2 mb-2" @click="downloadMIS">Download MIS</b-button>
    <b-button variant="success" class="ml-2 mb-2" @click="downloadChart">Download Charts</b-button>

    <div id="printchart" ref="document">
      <b-row class="mt-5">
        <b-col>
          <h3>Inbound :-</h3>
          <inbound-chart
            v-if="isChartVisible && chartSelected === 'store'"
            chart-type="storeid"
            :data="misChart"
          ></inbound-chart>
          <inbound-chart v-if="chartSelected === 'region'" chart-type="region" :data="regionChart"></inbound-chart>
          <inbound-chart
            v-if="isChartVisible && chartSelected === 'cluster'"
            chart-type="cluster"
            :data="clusterChart"
          ></inbound-chart>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col>
          <h3>Order :-</h3>
          <order-chart
            v-if="isChartVisible && chartSelected === 'store'"
            chart-type="storeid"
            :data="misChart"
          ></order-chart>
          <order-chart v-if="chartSelected === 'region'" chart-type="region" :data="regionChart"></order-chart>
          <order-chart
            v-if="isChartVisible && chartSelected === 'cluster'"
            chart-type="cluster"
            :data="clusterChart"
          ></order-chart>
        </b-col>
      </b-row>

      <!-- <b-row class="mt-5">
        <b-col>
          <h3>Outbound :-</h3>
          <outbound-chart
            v-if="isChartVisible && chartSelected === 'store'"
            chart-type="storeid"
            :data="misChart"
          ></outbound-chart>
          <outbound-chart v-if="chartSelected === 'region'" chart-type="region" :data="regionChart"></outbound-chart>
          <outbound-chart
            v-if="isChartVisible && chartSelected === 'cluster'"
            chart-type="cluster"
            :data="clusterChart"
          ></outbound-chart>
        </b-col>
      </b-row> -->
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import router from '@/router';
import { mapState, mapActions } from 'vuex';
import html2pdf from 'html2pdf.js';
import InboundChart from '../../components/charts/InboundChart.vue';
// import OutboundChart from '../../components/charts/OutboundChart.vue';
import OrderChart from '../../components/charts/OrderChart.vue';
import User from '../../model/user';

export default {
  name: 'MIS',
  metaInfo() {
    return {
      title: 'MIS',
      meta: [
        {
          name: 'mis',
          content: `Download mis`
        }
      ]
    };
  },
  components: { InboundChart, OrderChart },
  data() {
    return {
      title: 'MIS ',
      isChartVisible: false,
      regions: [{ text: 'All Regions', value: 'all' }],
      clusters: [{ text: 'All Clusters', value: 'all' }],
      stores: [{ text: 'All Stores', value: 'all' }],
      chartView: [
        { text: 'Region Wise', value: 'region' },
        { text: 'Cluster Wise', value: 'cluster' },
        { text: 'Store Wise', value: 'store' }
      ],
      regionSelected: 'all',
      clusterSelected: 'all',
      storeSelected: 'all',
      chartSelected: 'region',
      startDateValue: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      endDateValue: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
    };
  },
  mounted() {
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
  },
  methods: {
    ...mapActions('mis', ['download']),
    ...mapActions('store', ['listStore', 'listCluster', 'listRegion']),

    downloadChart() {
      html2pdf(this.$refs.document, {
        margin: 1,
        filename: 'Charts.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { letterRendering: true, width: 1320 },
        jsPDF: { unit: 'cm', orientation: 'portrait', format: 'a2' }
      });
    },
    printchart() {
      this.$htmlToPaper('printchart');
    },

    downloadMIS({ download = true }) {
      let storesList = [];
      if (this.storeSelected === 'all') {
        storesList = this.stores.map(s => s.value);
        storesList.shift();
      } else {
        storesList = this.storeSelected;
      }
      this.download({
        query: {
          startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
          enddate: moment(this.endDateValue).format('YYYY-MM-DD'),
          stores: storesList
        },
        download
      });
    },
    showChart() {
      this.downloadMIS({ download: false });
      this.isChartVisible = true;
    },
    ifAdmin() {
      if (this.user.role === User.userRole.administrator) {
        return true;
      }
      return false;
    },
    calculateRegionWise(data) {
      const counts = data.reduce((p, c) => {
        const mis = p;
        if (!Object.prototype.hasOwnProperty.call(mis, c.region)) {
          mis[c.region] = { ...c };
        }
        Object.keys(c).forEach(key => {
          if (Object.prototype.hasOwnProperty.call(c, key) && !['storeid', 'region', 'cluster', 'row'].includes(key)) {
            mis[c.region][key] += c[key];
          }
        });
        return mis;
      }, {});
      return Object.values(counts);
    },
    calculateClusterWise(data) {
      const counts = data.reduce((p, c) => {
        const mis = p;
        if (!Object.prototype.hasOwnProperty.call(mis, c.cluster)) {
          mis[c.cluster] = { ...c };
        }
        Object.keys(c).forEach(key => {
          if (Object.prototype.hasOwnProperty.call(c, key) && !['storeid', 'region', 'cluster'].includes(key)) {
            mis[c.cluster][key] += c[key];
          }
        });
        return mis;
      }, {});
      return Object.values(counts);
    }
  },
  computed: {
    ...mapState('mis', ['misList', 'misChart', 'misTable']),
    ...mapState('store', ['storeList', 'clusterList', 'regionList']),
    ...mapState('auth', ['user']),
    regionChart() {
      return this.calculateRegionWise(this.misChart);
    },
    clusterChart() {
      return this.calculateClusterWise(this.misChart);
    }
  },

  watch: {
    regionSelected(value) {
      if (value === 'all') {
        this.listStore({ router });
        this.listCluster({ router, query: { type: 'cluster' } });
      } else {
        this.listStore({ router, query: { by: 'region', value } });
        this.listCluster({ router, query: { type: 'cluster', by: 'region', value } });
      }
    },
    clusterSelected(value) {
      if (value === 'all') {
        this.listStore({ router });
      } else {
        this.listStore({ router, query: { by: 'cluster', value } });
      }
    },
    clusterList(newValue) {
      this.clusters = [{ text: 'All Clusters', value: 'all' }];
      this.clusters = [...this.clusters, ...newValue];
    },
    regionList(newValue) {
      this.regions = [{ text: 'All Regions', value: 'all' }];
      this.regions = [...this.regions, ...newValue];
    },
    storeList(newValue) {
      if (this.user.role === User.userRole.administrator) {
        this.stores = [{ text: 'All Stores', value: 'all' }];
        this.stores = [...this.stores, ...newValue];
      } else {
        this.stores = [{ text: 'All Stores', value: 'all' }];
        this.stores = [...this.stores, ...this.user.storeids.map(store => ({ text: store, value: store }))];
      }
      this.download({
        query: {
          startdate: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
          enddate: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
          stores: newValue.map(s => s.value).slice(1)
        },
        download: false
      });
    },
    misList(newValue) {
      if (newValue.length) {
        let csv = `,,,,,MIS from ${moment(this.startDateValue).format('DD-MM-YYYY')} to ${moment(
          this.endDateValue
        ).format('DD-MM-YYYY')}\n`;
        let processedValue = newValue;
        switch (this.chartSelected) {
          case 'region': {
            csv += 'Region,';
            processedValue = this.calculateRegionWise(newValue);
            processedValue = processedValue.map(item => {
              const region = item;
              delete region.storeid;
              delete region.cluster;
              delete region.row;
              return region;
            });
            break;
          }

          case 'cluster': {
            csv += 'Region,Cluster,';
            processedValue = this.calculateClusterWise(newValue);
            processedValue = processedValue.map(item => {
              const region = item;
              delete region.storeid;
              delete region.row;
              return region;
            });
            break;
          }

          default:
            csv += 'Row Number,Store Id,Region,Cluster,';
            break;
        }
        csv +=
          'Calls Landed Total,' +
          'Calls Answered,Calls Not Answered,' +
          'Calls Busy,Calls Failed,Calls Feedback(Not Selected),' +
          'Calls Feedback(Serviced),Calls Feedback(Not Available),' +
          'Calls Feedback(Enquiry Call),Calls Feedback(Out of Service Area),' +
          'Calls Feedback(Not Reachable),Single Order,' +
          'Bulk Order,IVR Order,Acknowledged,Delivered,Call Recordings,' +
          'Recordings Feedback(Not Selected),Recordings Feedback(Serviced),' +
          'Recordings Feedback(Not Available),Recordings Feedback(Enquiry Call),' +
          'Recordings Feedback(Out of Service Area),Recordings Feedback(Not Reachable),' +
          // 'Outbound(Call Dialed), Outbound(Call Connected),Outbound(Terminated By Staff),Outbound(Ringed),Outbound(User Not Available' +
          '\n';
        const sumRow = [];
        processedValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            csv += `"${val}"`;
            csv += ',';
            if (typeof val !== 'number' || index === 0) {
              sumRow[index] = '';
              return;
            }

            if (sumRow[index]) {
              sumRow[index] += val;
            } else {
              sumRow[index] = val;
            }
          });
          csv += '\n';
        });
        sumRow[0] = 'India Total';
        csv += `${sumRow.join(',')}\n`;

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'MIS.csv';
        anchor.click();
      }
    }
  }
};
</script>
